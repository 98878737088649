<template>
<div class="PageNotFound">

  <div class="mainTheme">
    <div class="container" style="min-height: 100vh; display: flex; justify-content: center; align-items: center;">
      <div class="insideBox">
        <h3>404 查無文章內容</h3>
        <div>在 {{countdown}} 秒內回到首頁</div>
        <div class="mt-4"><el-button type="primary" @click="goBackIndex()">立即回首頁</el-button></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import {ref} from "vue";

export default {
  name: "PageNotFound",
  setup(){
    let countdown = ref(5)
    let timer = null

    function loading(){
      countdown.value--
    }
    function clearTimer(){
      clearInterval(timer)
      timer = null
    }
    loading()
    timer = setInterval(() =>{
      if(countdown.value === 0){
        clearTimer()
      }else{
        loading()
      }
    },1000)


    setTimeout(() => {
      goBackIndex()
    }, 5000)

    function goBackIndex(){
      clearTimer()
      router.push({name:'PostIndex'})
    }

    return{
      countdown,
      goBackIndex
    }
  }
}
</script>

<style scoped>

</style>

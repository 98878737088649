<template>
<div class="AdminMsgList">
  <div class="row">
    <div class="col-12">
      <h3>留言</h3>
    </div>
    <div class="col-12">
      <table class="w-100 adminTable">
        <thead>
          <tr>
            <td style="min-width: 12rem">留言者</td>
            <td>留言內容</td>
            <td style="min-width: 22rem">原始文章</td>
            <td style="min-width: 7rem">操作</td>
          </tr>
        </thead>
        <tbody style="word-break: break-all;">
          <tr v-for="(item) in msgList.data" :key="item">
            <td style="vertical-align: top;">
              <div v-if="item.status === 2">[待審核]</div>
              <div style="font-weight: bold">{{item.name}}</div>
              <div class="small">{{item.createdAt}}</div>
              <div class="small">{{item.email}}</div>
            </td>
            <td class="msgAreaTd">
              <div class="adminResponse" v-if="item.msgBox === true">
                管理者回覆：
                <table class="w-100">
                  <tr>
                    <td><el-input v-model="modelText.ReplyBody.content" :rows="2" type="textarea"/></td>
                    <td style="width: 10px;"></td>
                    <td style="width: 3rem"><el-button title="回覆留言" type="primary" plain class="pt-1 pb-1 ps-0 pe-0 w-100" @click="sendReplyMessage">送出</el-button></td>
                  </tr>
                </table>
              </div>
              <!--   單篇留言    -->
              <div v-if="item.children.length === 0">
                {{item.content}}
              </div>
              <!--   有子留言回應    -->
              <div v-if="item.children.length > 0">
                <div class="childrenMsgArea">
                  <div v-for="item in item.children" :key="item" class="pb-3">
                    <span class="colorText2 name" style="font-style: italic;" :class="item.isAdmin === true ? 'isAdmin':''">{{item.name}}<span v-if="item.isAdmin === true">(管理員)</span> 說： </span>{{item.content}}
                    <div class="small time mt-1">
                      <el-popover v-if="item.email !== ''" placement="top" :width="200" trigger="click"
                                  :content="item.email">
                        <template #reference>
                          <div class="mailIcon"><el-icon><Message /></el-icon></div>
                        </template>
                      </el-popover>
                      <div>{{item.createdAt.slice(0, 16)}}</div>
                      <div class="deleteChildrenMsg" data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(1, item)"><el-icon><Delete /></el-icon>刪除</div>
                    </div>
                  </div>
                </div>
                <div class="startMsgBox small">
                  原始留言：{{item.content}}
                </div>
              </div>
            </td>
            <td class="small" style="word-break: break-all;" >
              <router-link :to="postToLink(item)" target="_blank">{{item.postsTitle}}</router-link>
            </td>
            <td>
              <el-button title="審核留言" v-if="item.status === 2" type="info" plain class="pt-1 pb-1 ps-2 pe-2"  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(0, item)"><el-icon><Finished /></el-icon></el-button>
              <el-button title="刪除留言" type="danger" plain class="pt-1 pb-1 ps-2 pe-2"  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(1, item)"><el-icon><Delete /></el-icon></el-button>
              <el-button title="回覆留言" v-if="item.status === 1" type="info" plain class="pt-1 pb-1 ps-2 pe-2" @click="ReplyMessage(item)"><el-icon><Edit /></el-icon></el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- 編輯/刪除Modal -->
  <div class="modal fade" id="pageModal" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >留言操作</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>{{modelText.data.name}} 說:</div>
          <div class="mt-1" style="word-break: break-all">{{modelText.data.content}}</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="approvedMsg(1)" v-if="modelType===0">顯示前台</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteMsg" v-if="modelType===0 || modelType===1">刪除留言</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {reactive, ref} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import { Finished, Delete, Message, Edit} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";

export default {
  name: "AdminMsgList",
  components:{Delete, Finished, Message, Edit},
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    //現有的標籤
    let msgList = reactive({
      data:[]
    })

    function startPage(){
      msgList.data =[]

      axios.get(api.adminMsgList+'?pageSize=1000&currentPage=1', {headers: {Authorization: 'Bearer '+adminToken}}).then(res=>{
        if (res.data.msg === "success"){
          msgList.data  = res.data.data.dataList
          for (let i=0; i<msgList.data.length; i++){
            msgList.data[i].msgBox = false //回覆視窗
          }
        }
      })
    }
    startPage()

    //連接去文章
    function postToLink(item){
      //分類一層
      if (item.class.length===1){
        return '/post/'+item.class[0] + '/' + item.postsName
      }
      //分類兩層
      if (item.class.length===2){
        return '/post/'+item.class[0] + '/' + item.class[1] + '/' + item.postsName
      }
    }

    let modelType = ref(0) //跳窗 0審核留言 1刪除留言
    let modelText = reactive({
      data:{
        name:'',
        content:''
      },
      deleteBody:{
        id:0
      },
      editBody:{
        id: 0,
        status:1 //1=公開的留言串, 2=隱藏的留言串
      },
      ReplyBody:{
        name: "",
        mail: "",
        content: "",
        postsId: 1, // 文章id
        parentId:0, // 最上層帶0
        status: 1 //1=公開的留言串, 2=隱藏的留言串
      }
    })
    modelText.ReplyBody.name = window.g.adminName

    //開啟跳窗
    function openModel(type, item){
      modelType.value = type

      modelText.data.name = item.name
      modelText.data.content = item.content

      modelText.deleteBody.id =  item.id

      modelText.editBody.id = item.id
    }

    //通過留言.隱藏留言
    function approvedMsg(value){
      modelText.editBody.status = value
      axios.patch(api.adminMsgEdit,modelText.editBody,{ headers: { 'Authorization': 'Bearer ' + adminToken }, }
      ).then((res) => {
        if (res.data.msg === "success"){
          ElMessage.success('變更成功')
          startPage()
        }else {
          ElMessage.error('變更失敗')
        }
      })
    }

    //刪除留言
    function deleteMsg(){
      axios.delete(api.adminMsgEdit,{headers: {Authorization: 'Bearer ' + adminToken},data:modelText.deleteBody}).then((res)=>{
        if (res.data.msg === "success"){
          ElMessage.success('刪除成功')
          startPage()
        }else {
          ElMessage.error('變更失敗')
        }
      })
    }

    //回覆留言
    function ReplyMessage(item){
      modelText.ReplyBody.postsId = item.postsId
      modelText.ReplyBody.parentId = item.id
      item.msgBox = !item.msgBox
    }
    function sendReplyMessage(){
      axios.post(api.adminMsgEdit,modelText.ReplyBody,{ headers: { 'Authorization': 'Bearer ' + adminToken }, }
      ).then((res) => {
        if (res.data.msg === "success"){
          ElMessage.success('變更成功')
          startPage()
        }else {
          ElMessage.error('變更失敗')
        }
      })
    }


    return {
      msgList,
      postToLink,
      openModel,
      modelType,
      modelText,
      approvedMsg,
      deleteMsg,
      ReplyMessage,
      sendReplyMessage
    }
  }
}
</script>

<style scoped>

</style>

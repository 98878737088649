import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "../src/assets/css/style.css"

//---- bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"

//---- ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhTw from 'element-plus/dist/locale/zh-tw.mjs' //繁體中文化

//--- axios
import Axios from 'axios'
const app = createApp(App)
app.config.globalProperties.$http = Axios

//---google機器人認證
import { VueReCaptcha } from 'vue-recaptcha-v3'

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

//---CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue';

//---google adsense
app.config.compilerOptions.isCustomElement = tag => tag.startsWith('ins')

createApp(App).use(router)
    .use(ElementPlus, {locale: zhTw,})
    .use( CKEditor )
    .use(VueReCaptcha, {siteKey: '6LcCkD0pAAAAAPSod_5c8FGxlgjNiNTSBJlFXL8N', loaderOptions: {useRecaptchaNet: true,},})
    .mount('#app')

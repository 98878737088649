<template>
<div class="postList row">
  <div class="col-lg-6 mb-4" v-for="item in showDataList.data" :key="item">
    <div class="card">
      <div class="card-body text-start">
        <div class="className">
          <router-link :to="classLink(item)"><el-icon><TakeawayBox /></el-icon>{{item.classTw[item.classTw.length-1]}}</router-link>
        </div>
        <div class="listTitle">
          <router-link :to="postLink(item)">{{item.title}}</router-link></div>
        <div class="postText small">{{item.content}}...</div>
        <div class="linkTo small text-end mt-2"><router-link :to="postLink(item)">Read more</router-link></div>
      </div>
    </div>
  </div>
  <div class="col-12 pb-5">
    <div class="text-start" v-if="showDataList.data.length===0 && showDataList.loading === false">此分類沒有文章</div>
    <el-pagination v-if="showDataList.data.length>0" style="display: flex; justify-content: center; align-items: center;" background layout="prev, pager, next" :page-count="showDataList.settings.totalCount / showDataList.settings.pageSize" @current-change="handleCurrentChange" :current-page="showDataList.settings.currentPage"/>
  </div>
</div>
</template>

<script>
import {TakeawayBox} from "@element-plus/icons-vue";
import {inject, reactive, watch} from "vue";
import {api} from "@/api/api";
import axios from "axios";
import {useRoute} from "vue-router";

export default {
  name: "post-list",
  components:{TakeawayBox},
  setup(){
    const route = useRoute()

    let showDataList = reactive({
      data:[],
      loading:false,
      settings:{}
    })
    showDataList.settings = inject('setShowDataList')

    //路徑 麵包屑
    let BreadLink = reactive({
      data:{
        linkGroup:[]
      }
    })
    BreadLink.data = inject('getBreadLink')

    //文章連結
    function postLink(item){
      let linkText =''
      for (let i=0; i<item.class.length; i++){
        if (item.class.length === 1){
          linkText = linkText +'/post/'+item.class[0]+ '/' +item.postName
        }else {
          if (i === 0){
            linkText = linkText +'/post/'+item.class[i]
          }else {
            linkText = linkText +'/'+item.class[i]
            if (i === (item.class.length -1) ){
              linkText = linkText+'/'+ item.postName
            }
          }
        }
      }
      return linkText
    }
    //分類連結
    function classLink(item){
      let linkText ='/class'
      for (let i=0; i<item.class.length; i++){
        linkText = linkText +'/'+item.class[i]
      }
      return linkText
    }

    //更換頁數
    function handleCurrentChange(val){
      showDataList.settings.currentPage = val
      getPostList()
    }

    //文章列表
    function getPostList(){
      let apiLink = api.postList+'?pageSize='+showDataList.settings.pageSize+'&currentPage='+showDataList.settings.currentPage+'&contentNum='+showDataList.settings.contentNum
          +'&searchTitle='+showDataList.settings.searchTitle+'&sort='+showDataList.settings.sort+'&sortType='+showDataList.settings.sortType
      //用分類取得文章
      if (BreadLink.data.linkGroup.length>0 && showDataList.settings.tag ===''){
        for (let i=0; i<BreadLink.data.linkGroup.length; i++){
          apiLink = apiLink + '&class[]=' + BreadLink.data.linkGroup[i].class
        }
      }
      //用標籤取得文章
      if (showDataList.settings.tag !==''){
        apiLink = apiLink + '&tag=' + showDataList.settings.tag
      }
      showDataList.data= []
      showDataList.loading = true
      axios.get(apiLink).then(res => {
        showDataList.loading=false
        if (res.data.msg ==="success"){
          showDataList.data = res.data.data.dataList
          showDataList.settings.totalCount = res.data.data.totalCount

          for (let i = 0; i < showDataList.data.length; i++){
            showDataList.data[i].content = getNotHtml(showDataList.data[i].content)
          }
        }
      })
    }
    getPostList()

    //標籤列表
    let tagList = reactive({
      data:[]
    })

    //取得標籤列表
    function getTagList(){
      tagList.data = []
    }
    getTagList()

    watch(() => route.path, () => {
      getPostList()
    })


    function getNotHtml(html){
      let relStyle = /style\s*?=\s*?([‘"])[\s\S]*?\1/g; //去除style
      let relTag = /<.+?>/g; //去除標籤
      let relClass = /class\s*?=\s*?([‘"])[\s\S]*?\1/g;// 清除class
      let relSpan = /span\s*?=\s*?([‘"])[\s\S]*?\1/g;// 清除span
      let newHtml = "";
      if (html) {
        newHtml = html.replace(relStyle, "");
        newHtml = newHtml.replace(relTag, '');
        newHtml = newHtml.replace(relClass, '');
        newHtml = newHtml.replace(relSpan, '');
      }
      return newHtml;
    }

    return{
      showDataList,
      postLink,
      classLink,
      handleCurrentChange,
      BreadLink
    }
  }
}
</script>

<style scoped>

</style>

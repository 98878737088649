<template>
<div class="AdmintagList">
  <div class="row">
    <div class="col-12">
      <h3>共用區塊</h3>
    </div>
    <div class="col-lg-5 mb-4">
      <h5>設定代碼</h5>
      <el-input
          v-model="sendBody.new.code"
          type="textarea"
          placeholder='請輸入'
          rows="10"
      />
      <el-button class="mt-3" type="primary" @click="addClass">新增</el-button>
    </div>
    <div class="col-lg-7">
      <h5>現有標籤</h5>
      <table class="w-100 adminTable">
        <thead>
        <tr>
          <td>區塊</td>
          <td>操作</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in adList.data" :key="item">
          <td>區塊 ID:{{item.id}}</td>
          <td>
            <el-button type="info" plain  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(0, item)">編輯</el-button>
            <el-button type="danger" plain  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(1, item)">刪除</el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- 編輯/刪除Modal -->
  <div class="modal fade" id="pageModal" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content" v-if="modelType===0">
        <div class="modal-header">
          <h5 class="modal-title" >編輯區塊: id {{sendBody.edit.id}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <el-input
              v-model="sendBody.edit.code"
              type="textarea"
              placeholder='請輸入'
              rows="10"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="editClass">送出</button>
        </div>
      </div>
      <div class="modal-content" v-if="modelType===1">
        <div class="modal-header">
          <h5 class="modal-title" >刪除分類</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          請確認是否刪除「區塊 ID:{{sendBody.delete.id}}」?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteClass">刪除</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import { Top,Bottom} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";
export default {
  name: "AdminAdList",
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    let sendBody = reactive({
      new:{
        code:''
      },
      edit:{
        id:-1,
        code:''
      },
      delete:{
        id:-1
      }
    })

    let adList = reactive({
      data:{}
    })

    function startPage(){
      adList.data =[]

      axios.get(api.adminGoogleAdList+'?pageSize=1000&currentPage=1', {headers: {Authorization: 'Bearer '+adminToken}}).then(res=>{
        if (res.data.msg === "success"){
          adList.data  = res.data.data.dataList
        }
      })
    }
    startPage()

    let modelType = ref(0) //0.編輯/1.刪除

    //新增
    function addClass(){
      axios.post(api.adminGoogleAd, sendBody.new, {headers: {Authorization: 'Bearer '+adminToken}}).then(res => {
        if (res.data.msg === "success"){
          sendBody.new.code = ''
          ElMessage.success('新增成功')
          startPage()
        }else {
          ElMessage.error('新增失敗')
        }
      })
    }

    //編輯
    function editClass(){
      axios.patch(api.adminGoogleAd,sendBody.edit,{ headers: { 'Authorization': 'Bearer ' + adminToken }, }
      ).then((res) => {
        if (res.data.msg === "success"){
          ElMessage.success('編輯成功')
          startPage()
        }
      })
    }

    //刪除
    function deleteClass(){
      axios.delete(api.adminGoogleAd,{headers: {Authorization: 'Bearer ' + adminToken},data:sendBody.delete}).then((res)=>{
        if (res.data.msg === "success"){
          ElMessage.success('刪除成功')
          startPage()
        }
      })
    }

    //打開跳窗
    function openModel(type, item){
      modelType.value = type

      //編輯
      if (type === 0){
        sendBody.edit.id = item.id
        axios.get(api.adminGoogleAdText + '?id=' + sendBody.edit.id,{headers: {Authorization: 'Bearer ' + adminToken}}).then((res)=>{
          if (res.data.msg === "success"){
            sendBody.edit.code = res.data.data.code
          }
        })
      }
      //刪除
      if (type === 1){
        sendBody.delete.id = item.id
      }
    }

    return {
      sendBody,adList,
      Top, Bottom,
      modelType,
      editClass,
      deleteClass,
      openModel,
      addClass
    }
  }
}
</script>

<style scoped>

</style>

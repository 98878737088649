<template>
<div class="AdminImgList">
  <table>
    <tr>
      <td><h3>圖片管理</h3></td>
      <td class="ps-3">
        <el-button plain @click="openUpImgArea"><el-icon class="me-1"><Plus /></el-icon>新增檔案</el-button>
      </td>
    </tr>
  </table>
  <div class="addImgArea pt-3 pb-4" v-if="uploadArea === true">
    <div class="row" style="max-width: 1000px">
      <div class="col-12 mb-3">1.請先輸入圖片資料<span class="ms-4">2.再點 「開始上傳檔案」</span></div>
      <div class="col-md-6">
        <table class="w-100">
          <tr>
            <td style="width: 5rem">圖片標題</td>
            <td><el-input v-model="PictureData.data.title"/></td>
          </tr>
          <tr><td colspan="2" style="height: 0.7rem"></td></tr>
          <tr>
            <td>替代文字</td>
            <td><el-input v-model="PictureData.data.attributeText"/></td>
          </tr>
          <tr><td colspan="2" style="height: 0.7rem"></td></tr>
          <tr>
            <td>說明文字</td>
            <td><el-input v-model="PictureData.data.instructions"/></td>
          </tr>
        </table>
      </div>
      <div class="col-md-6">
        <table class="w-100">
          <tr>
            <td style="width: 5rem; vertical-align: top">內容說明</td>
            <td><el-input v-model="PictureData.data.content" type="textarea" rows="5"/></td>
          </tr>
        </table>
      </div>
      <div class="col-12 mt-3 text-end">
        <el-upload
            class="uploadImg"
            ref="upload"
            :limit="1"
            :http-request="uploadFile"
            :on-success="handleAvatarSuccess"
            accept="image/png, image/jpeg, image/gif, image/webp"
        >
          <el-button type="primary"><el-icon class="me-1"><Picture /></el-icon>開始上傳檔案</el-button>
        </el-upload>
      </div>
    </div>
  </div>
  <div class="timeSelectArea">
    <table>
      <tr>
        <td>顯示範圍：</td>
        <td>
          <el-input v-model="nowYear" style="width: 4rem"/>
        </td>
        <td class="ps-1 pe-1">年</td>
        <td><el-input v-model="nowMonth" style="width: 3rem"/></td>
        <td class="ps-1 pe-1">月</td>
        <td class="ps-3 pe-1">數量</td>
        <td>
          <el-select v-model="getListBody.data.pageSize" style=" width: 110px;">
            <el-option
                v-for="item in sizeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            />
          </el-select>
        </td>
        <td class="ps-2">
          <el-button plain @click="getNowImgList('month')"><el-icon class="me-1"><Search /></el-icon>篩選</el-button>
        </td>
        <td class="ps-4">
          <el-button plain @click="getNowImgList('all')"><el-icon class="me-1"><Search /></el-icon>顯示全部</el-button>
        </td>
      </tr>
    </table>

  </div>
  <div class="row">
    <div class="col-1" v-for="item in imgList.data" :key="item">
      <div class="imgItemBox" @click="showMoreData(item)" data-bs-toggle="modal" data-bs-target="#pageModal">
        <img :src="item.url"/>
      </div>
    </div>
  </div>
  <div class="col-12 pb-5 pt-4">
    <el-pagination v-if="imgList.data.length>0" style="display: flex; justify-content: center; align-items: center;" background layout="prev, pager, next"
                   :page-count="imgList.totalCount / getListBody.data.pageSize"
                   @current-change="handleCurrentChange" :current-page="getListBody.data.currentPage"/>
  </div>
  <!--預覽Modal -->
  <div class="modal fade" id="pageModal" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
<!--      瀏覽圖片細節-->
      <div class="modal-content" v-if="modelType===0">
        <div class="modal-header">
          <h6 class="modal-title" >詳細資料</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-0">
          <table class="w-100">
            <tr>
              <td class="text-center p-3" style="background-color: #ebebeb;">
                <img :src="flieData.data.url" style="max-height: 80vh"/>
              </td>
              <td style="width: 350px" class="p-4">
                <table class="w-100">
                  <tr>
                    <td style="width: 5rem">ID</td>
                    <td>{{flieData.data.id}}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>圖片標題</td>
                    <td>{{flieData.data.title}}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>創建時間</td>
                    <td>{{ flieData.data.createdA }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>修改時間</td>
                    <td>{{ flieData.data.updatedAt }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>替代文字</td>
                    <td>{{ flieData.data.attributeText }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>說明文字</td>
                    <td>{{ flieData.data.instructions }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>內容說明</td>
                    <td>{{ flieData.data.content }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height: 0.4rem"></td>
                  </tr>
                  <tr>
                    <td>圖片連結</td>
                    <td>
                      <div><el-input v-model="flieData.data.url" ref="imgUrl" /></div>
                    </td>
                  </tr>
                </table>
                <el-button type="danger" plain class="mt-4" @click="modelType=1">刪除圖片</el-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
<!--      刪除圖片-->
      <div class="modal-content" v-if="modelType===1">
        <div class="modal-header">
          <h6 class="modal-title" >刪除圖片</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          請確認是否刪除「{{flieData.data.title}}」此圖片?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteImg">刪除</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import { Plus, Search, Picture} from "@element-plus/icons-vue";
import {ElMessage } from "element-plus";
import {uploadImg} from "@/views/admin-img/upload-img";
import {serialImgNumber} from "@/views/admin-img/serial-img-number";
import {ImgVerify} from "@/views/admin-img/img-verify";

export default {
  name: "AdminImgList",
  components:{Plus, Search, Picture},
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    //現有的圖片
    let imgList = reactive({
      data:[],
      totalCount:0
    })

    const sizeList =[
      {name:'20筆/頁', value:20},
      {name:'50筆/頁', value:50},
      {name:'100筆/頁', value:100},
    ]

    let getListBody = reactive({
      data:{
        filterDate:'', //時間YYYYMM
        pageSize:20, //一頁多少筆
        currentPage:1 //目前頁數
      }
    })

    //選擇年分.月份
    let nowYear = ref('') //年
    let nowMonth = ref('')//月
    const nowDate = new Date()
    nowYear.value = nowDate.getFullYear().toString()
    nowMonth.value = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : (nowDate.getMonth() + 1)

    //取得現在圖片列表
    let nowType = ref('') //記住現在的搜尋模式
    function getNowImgList(type){
      nowType.value = type
      imgList.data = []
      let nowApi = api.adminFilesList + '?pageSize='+getListBody.data.pageSize+'&currentPage='+getListBody.data.currentPage
      //有篩選月份(需填對年月位數)
      if (nowYear.value.length === 4 && nowMonth.value.toString().length===2 && type==='month'){
        getListBody.data.filterDate = nowYear.value+ nowMonth.value
        nowApi = nowApi+'&filterDate='+getListBody.data.filterDate
      }
      console.log('getNowImgList')
      axios.get(nowApi, {headers: {Authorization: 'Bearer '+adminToken}}).then(res=>{
        if (res.data.msg === "success") {
          imgList.data = res.data.data.dataList
          imgList.totalCount = res.data.data.totalCount
          console.log('getNowImgList end')
        }
      })

    }
    getNowImgList('all')

    //換頁
    function handleCurrentChange(val){
      getListBody.data.currentPage = val
    }

    //新增圖片檔案
    let PictureData = reactive({
      data:{
        title: "", // 標題
        attributeText: "", // 替代文字
        instructions: "", // 說明文字
        content: "" // 內容說明
      },
      folder:{
        name:'' //圖片放入的資料夾
      }
    })
    async function uploadFile(fileObj) {
      //上傳圖片用到的資料
        let resValue = ref()
        let getData = ref()
        let isImage = ref(false)
        await serialImgNumber(adminToken, getData, isImage, fileObj, PictureData)
      if (isImage.value === true){
        await uploadImg(adminToken, fileObj, getData, resValue)
        await ImgVerify(adminToken, getData, resValue, PictureData)
        await getNowImgList('all')
      }else {
        ElMessage.error('上傳的檔案不是圖片')
      }
      PictureData.data.title = ''
      PictureData.data.attributeText = ''
      PictureData.data.instructions = ''
      PictureData.data.content = ''
      uploadArea.value = false
    }
    //清除已經上傳成功的內容
    let upload = ref()
    function handleAvatarSuccess() {
      upload.value.clearFiles()
    }

    //是否顯示上傳圖片位置
    let uploadArea = ref(false)
    function openUpImgArea(){
      uploadArea.value = !uploadArea.value
      if (uploadArea.value){
        //讓圖片標題為年月日十分秒
        const today = new Date()
        const year = today.getFullYear().toString()
        const month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
        const date = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        const HH = today.getHours().toString()
        const MM = today.getMinutes().toString()
        const ss = today.getSeconds().toString()
        PictureData.data.title = year + month.toString() + date.toString() + HH + MM + ss
        PictureData.folder.name = year + month.toString()
      }
    }

    //瀏覽圖片
    let modelType = ref(0)
    let flieData = reactive({
      data: {
        account: "",
        attributeText: "",//替代文字
        content: "",//內容說明
        createdA: "",//創建時間
        updatedAt: "",//修改時間
        id: 1,
        instructions: "",//說明文字
        title: "",//圖片標題
        url: "" //圖片連結
      }
    })

    //打開圖片跳窗時代入資料
    function showMoreData(item){
      flieData.data = item
      modelType.value = 0
    }

    //刪除圖片
    function deleteImg(){
      const deleteBody = { filesId:flieData.data.id }
      axios.delete(api.adminFileEdit, {headers: {Authorization: 'Bearer '+adminToken}, data:deleteBody}).then(res => {
        if (res.data.msg==="success"){
          getNowImgList(nowType.value)
        }else {
          ElMessage.error('刪除失敗')
        }
      })
    }


    return {
      imgList,
      modelType,
      showMoreData,
      flieData,
      deleteImg,
      nowYear,
      nowMonth,
      getNowImgList,
      getListBody,
      sizeList,
      handleCurrentChange,
      uploadFile,
      handleAvatarSuccess,
      upload,
      PictureData,
      openUpImgArea,
      uploadArea
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="AdminPostEdit text-start">
    <el-container>
      <el-header class="p-0">
        <table class="w-100" style="border-bottom: 1px solid #dadada">
          <tr>
            <td class="p-0">
              <div class="goBackList" @click="$router.push({name:'AdminPostList'})">
                <img :src="require('@/assets/images/list.png')">
              </div>
            </td>
            <td>
              <table style="float: right" v-if="editContent.createdAt !== ''">
                <tr>
                  <td class="colorText1 p-2">修改時間</td>
                  <td class="p-2">
                    {{editContent.createdAt}}
                  </td>
                </tr>
              </table>
            </td>
            <td class="text-end pe-3" style="width: 100px">
              <el-button type="primary" @click="sendPost">儲存</el-button>
            </td>
          </tr>
        </table>
      </el-header>
      <el-container>
        <el-main>
          <el-input v-model="editContent.data.title" placeholder="文章標題" />
          <div style="height: 1.5rem"></div>
          <ckeditor :editor="editor" v-model="editContent.data.content" :config="editorConfig"></ckeditor>
          <div class="mt-5">文章關鍵字</div>
          <el-input v-model="editContent.data.keywords" />

        </el-main>
        <el-aside width="280px">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="文章摘要" name="1">
              <table>
                <tr>
                  <td class="colorText1 pt-3 pb-3">可見度</td>
                  <td class="p-3">
                    <el-button v-if="editContent.data.status === 2" @click="editContent.data.status = 1">草稿</el-button>
                    <el-button v-if="editContent.data.status === 1" @click="editContent.data.status = 2">公開</el-button>
                  </td>
                </tr>
                <tr>
                  <td class="colorText1 pt-3 pb-3">網址</td>
                  <td class="p-3">
                    <el-input class="w-100" v-model="editContent.data.postName" placeholder="文章網址" />
                  </td>
                </tr>
                <tr>
                  <td class="colorText1 pt-3 pb-3">瀏覽人次</td>
                  <td class="p-3">{{editContent.visitors}}</td>
                </tr>

              </table>
            </el-collapse-item>
            <el-collapse-item title="分類" name="2">
              <el-select v-model="classesIdsNow">
                <el-option
                    v-for="item in classSelect.data"
                    :key="item.classesIds"
                    :label="item.classTw"
                    :value="item.classesIds"
                />
              </el-select>
            </el-collapse-item>
            <el-collapse-item title="標籤" name="3">
              <div>
                <div class="colorText1 mb-2">目前標籤</div>
                <el-tag class="me-2 mb-3" type="info" v-for="item in editContent.nowTagList" :key="item" closable @close="handleClose(item)">{{ item.tagTw }}</el-tag>
                <div class="mt-4 mb-2 colorText1">可選標籤</div>
                <div>
                  <el-tag class="me-2 mb-3" type="info" v-for="item in tagList.data" :key="item" @click="newTag(item)">{{ item.tagTw }}</el-tag>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="封面圖片" name="4">
              444
            </el-collapse-item>
          </el-collapse>
        </el-aside>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import axios from "axios";
import {api} from "@/api/api";
import {reactive, ref} from "vue";
import router from "@/router";
import {ClassSelectList} from "@/views/class-select-list";
import {ElMessage} from "element-plus";
import Editor from '@ckeditor/ckeditor5-custom-build/build/ckeditor';
import MyUploadAdapter from "@/views/admin-img/MyUploadAdapter";

export default {
  name: "AdminPostEdit",
  methods: {
    router() {
      return router
    }
  },
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token
    const route = useRoute();
    const postId = route.query.id
    const postType = route.query.type

    const activeNames = ref(['1'])

    // 取得選單用分類
    let classSelect = reactive({
      data:[]
    })
    let searchClass = ref('') //搜尋分類classtw

    //現有的標籤
    let tagList = reactive({
      data:[]
    })
    //取得分類列表+標籤列表
    function getNowSelectList(){
      ClassSelectList(classSelect.data, searchClass.value, 3)

      tagList.data =[]
      axios.get(api.getTagList+'?pageSize=1000&currentPage=1', {headers: {Authorization: 'Bearer '+adminToken}}).then(res=>{
        if (res.data.msg === "success"){
          let getData  = res.data.data.dataList

          for (let i=0; i<getData.length; i++){
            const haveSon = getData[i]
            tagList.data.push(haveSon)
          }
        }
      })
    }

    let classesIdsNow = ref('')
    //讀取編輯內容
    let editContent = reactive({
      data:{
        title:'', //文章標題
        classesIds:[], //分類id
        tagsIds:[], //tag id
        postName:'', //文章link
        status:2, //1=public(公開) 2=draft(草稿)
        content:'', //html
        keywords:'' //seo關鍵字
      },
      visitors:'', //瀏覽人次
      createdAt:'', //文章儲存時間
      nowTagList:[] //目前選的tagTw+id
    })
    async function getPostData(){
      await getNowSelectList()
      axios.get(api.adminPostGet + '?id=' + postId, {headers: {Authorization: 'Bearer '+adminToken}}).then(res => {
        editContent.data.title = res.data.data.title
        editContent.data.status = res.data.data.status
        editContent.data.postName = res.data.data.postName
        classesIdsNow.value = res.data.data.classesIds.join()
        editContent.data.content = res.data.data.content
        editContent.data.keywords = res.data.data.keywords

        for (let i=0; i<res.data.data.tagTw.length; i++){
          editContent.nowTagList.push({tagTw:res.data.data.tagTw[i], id:res.data.data.tagsIds[i]})
        }
        editContent.createdAt = res.data.data.createdAt
        editContent.visitors = res.data.data.visitors
      })
    }
    if (postType === 'edit'){
      editContent.data.id = parseInt(postId)
      getPostData()
    }else {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
      const date = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
      editContent.data.postName=year+'-'+month+'-'+date
      getNowSelectList()
    }

    //刪除標籤
    function handleClose(item){
      editContent.nowTagList.splice(editContent.nowTagList.indexOf(item), 1)
    }

    //新增標籤
    function newTag(item){
      //先排除重複的標籤
      const getItem = editContent.nowTagList.filter((k) => {
        return item.id === k.id
      })
      if (getItem.length === 0){
        editContent.nowTagList.push({tagTw:item.tagTw, id:item.id})
      }
    }

    //送出
    function sendPost(){
      if (classesIdsNow.value !==''){
        const classList = classesIdsNow.value.split(',')
        for (let i=0; i<classList.length; i++){
          editContent.data.classesIds.push(parseInt(classList[i]))
        }
        if (editContent.nowTagList.length >0) {
          for (let i = 0; i < editContent.nowTagList.length; i++) {
            editContent.data.tagsIds.push(editContent.nowTagList[i].id)
          }
        }
        if (postType === 'new') {
          axios.post(api.adminPostEdit, editContent.data, {headers: {'Authorization': 'Bearer ' + adminToken},}
          ).then((res) => {
            if (res.data.msg === "success") {
              ElMessage.success('新增成功')
              router.push({name: 'AdminPostList'})
            }
          })
        }
        if (postType === 'edit') {
          axios.patch(api.adminPostEdit, editContent.data, {headers: {'Authorization': 'Bearer ' + adminToken},}
          ).then((res) => {
            if (res.data.msg === "success") {
              ElMessage.success('編輯成功')
              router.push({name: 'AdminPostList'})
            }
          })
        }
      }else {
        ElMessage.error('尚未選擇分類')
      }

    }


    let editor = Editor

    let editorConfig = {
      extraPlugin: [uploader]
    }
    function uploader(editor){
      // 自定义上传图片插件
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new MyUploadAdapter( loader );
      };
    }


    return{
      sendPost,
      editContent,
      activeNames,
      classSelect,
      searchClass,
      classesIdsNow,
      tagList,
      handleClose,
      newTag,
      editor,
      editorConfig
    }
  }
}
</script>

<style scoped>

</style>

<template>
<div class="AdminClassList">
  <div class="row">
    <div class="col-12">
      <h3>分類</h3>
    </div>
    <div class="col-5">
      <h5>新增分類</h5>
      <div class="mt-3">名稱</div>
      <el-input v-model="newBody.data.classTw" />
      <div class="mt-3">代稱</div>
      <el-input v-model="newBody.data.class" />
      <div class="mt-3">上一層分類</div>
      <el-select v-model="newBody.data.parentId">
        <el-option
            v-for="item in classSelect.data"
            :key="item.id"
            :label="item.classTw"
            :value="item.id"
        />
      </el-select>
      <div class="mt-4">
        <el-button type="primary" @click="addClass">新增分類</el-button>
      </div>
    </div>
    <div class="col-7">
      <h5>現有分類</h5>
      <table class="w-100 adminTable">
        <thead>
          <tr>
            <td>名稱</td>
            <td>代稱</td>
            <td>文章數量</td>
            <td>排序</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in classList.data" :key="item">
            <td>{{item.showName}}</td>
            <td>{{item.class}}</td>
            <td>{{item.quantity}}</td>
            <td>
              <el-button :icon="Top" circle @click="orderChange('up', item)"/>
              <el-button :icon="Bottom" circle @click="orderChange('down', item)"/>
            </td>
            <td>
              <el-button type="info" plain  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(0, item)">編輯</el-button>
              <el-button type="danger" plain  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(1, item)">刪除</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- 編輯/刪除Modal -->
  <div class="modal fade" id="pageModal" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content" v-if="modelType===0">
        <div class="modal-header">
          <h5 class="modal-title" >編輯分類: id {{editBody.data.id}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mt-3">名稱</div>
          <el-input v-model="editBody.data.classTw" />
          <div class="mt-3">代稱</div>
          <el-input v-model="editBody.data.class" />
          <div class="mt-3">上一層分類</div>
          <el-select v-model="editBody.data.parentId">
            <el-option
                v-for="item in classSelect.data"
                :key="item.id"
                :label="item.classTw"
                :value="item.id"
            />
          </el-select>
          <div class="mt-3">排序</div>
          <el-input v-model="editBody.data.order" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="editClass">送出</button>
        </div>
      </div>
      <div class="modal-content" v-if="modelType===1">
        <div class="modal-header">
          <h5 class="modal-title" >刪除分類</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          請確認是否刪除「{{deleteClassTw}}」此分類?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteClass">刪除</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import { Top,Bottom} from "@element-plus/icons-vue";
import {ClassSelectList} from '../class-select-list'
export default {
  name: "AdminClassList",
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    //現有的分類
    let classList = reactive({
      data:[]
    })
    //取得選單用分類
    let classSelect = reactive({
      data:[]
    })


    function startPage(){
      classList.data =[]
      classSelect.data=[]
      axios.get(api.postClassList).then(res=>{
        if (res.data.msg === "success"){
          let getData  = res.data.data

          for (let i=0; i<getData.length; i++){
            const haveSon = getData[i]
            haveSon.showName = haveSon.classTw
            haveSon.parentId = 0
            classList.data.push(haveSon)
            if (haveSon.children !==[]){
              for (let c=0; c < haveSon.children.length; c++){
                haveSon.children[c].showName = '　– '+haveSon.children[c].classTw
                haveSon.children[c].parentId=haveSon.id
                classList.data.push(haveSon.children[c])

                let haveSon2 = haveSon.children[c].children
                if (haveSon2 !==[]){
                  for (let d=0; d < haveSon2.length; d++){
                    haveSon2[d].showName = '　　–– '+haveSon2[d].classTw
                    haveSon2[d].parentId=haveSon.children[c].id
                    classList.data.push(haveSon2[d])
                  }
                }
              }
            }
          }
        }
      })
      ClassSelectList(classSelect.data, '', 2)
    }
    startPage()

    //新增分類
    let newBody = reactive({
      data:{
        parentId:0, //父層分類ID(第一層的話不送此參數)
        class:"", //文章分類英文
        classTw:"", //文章分類中文
        order:1 //排列順序
      }
    })
    function addClass(){
      axios.post(api.editClass, newBody.data, {headers: {Authorization: 'Bearer '+adminToken}}).then(res => {
        if (res.data.msg === "success"){
          ElMessage.success('新增成功')
          startPage()
        }else {
          ElMessage.error('新增失敗')
        }
      })
    }

    let modelType = ref(0) //0.編輯/1.刪除

    //刪除分類
    let deleteBody = ref() //刪除時送出內容
    let deleteClassTw = ref() //刪除提示刪除哪個分類
    function deleteClass(){
      axios.delete(api.editClass,{headers: {Authorization: 'Bearer ' + adminToken},data:deleteBody.value}).then((res)=>{
        if (res.data.msg === "success"){
        ElMessage.success('刪除成功')
        startPage()
        }
      })
    }

    //編輯分類
    let editBody = reactive({
      data:{
        id:1, //分類ID(新增不會有此欄位)
        parentId:0, //父層分類ID
        class:"", //文章分類英文
        classTw:"", //文章分類中文
        order:1 //排列順序
      }
    })
    function editClass(){
      axios.patch(api.editClass,editBody.data,{ headers: { 'Authorization': 'Bearer ' + adminToken }, }
      ).then((res) => {
        if (res.data.msg === "success"){
          ElMessage.success('編輯成功')
          startPage()
        }
      })
    }

    //打開跳窗
    function openModel(type, item){
      modelType.value = type

      //編輯
      if (type === 0){
        editBody.data.id = item.id
        editBody.data.parentId = item.parentId
        editBody.data.class = item.class
        editBody.data.classTw = item.classTw
        editBody.data.order = item.order
      }
      //刪除
      if (type === 1){
        deleteBody.value = {id:item.id}
        deleteClassTw.value = item.classTw
      }
    }

    //改變排序
    function orderChange(type, item){
      editBody.data.id = item.id
      editBody.data.parentId = item.parentId
      editBody.data.class = item.class
      editBody.data.classTw = item.classTw
      editBody.data.order = item.order

      if (type === 'up' && item.order !== 0 && item.order !== 1){
        editBody.data.order = editBody.data.order - 1
        editClass()
      }
      if (type === 'down'){
        editBody.data.order = editBody.data.order + 1
        editClass()
      }
    }



    return {
      classList,
      newBody,
      classSelect,
      addClass,
      modelType,
      openModel,
      deleteClass,
      deleteClassTw,
      editBody,
      editClass,
      Top, Bottom,
      orderChange
    }
  }
}
</script>

<style scoped>

</style>

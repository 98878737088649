import axios from "axios";
import {api} from "@/api/api";

 export function serialImgNumber(adminToken, getData, isImage, fileObj, PictureData){
    return new Promise((resolve, reject) => {
        //判斷是否為圖片
        isImage.value = fileObj.file.type.includes('image/')
        if (isImage.value === true){
            //編序
            let sendBody = {
                fileType: fileObj.file.type.replace("image/",""),
                folder: PictureData.folder.name
            }
            console.log('api.adminFilesUrl')
            axios.post(api.adminFilesUrl, sendBody, {headers: {Authorization: 'Bearer ' + adminToken}}).then(res => {
                if (res.data.msg === "success") {
                    getData.value = res.data.data
                    console.log('api.adminFilesUrl End')
                    resolve(getData, isImage)
                }else {
                    reject(getData, isImage)
                }
            })
        }else {
            reject(getData, isImage)
        }
    })
}

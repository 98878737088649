import axios from 'axios'

axios.defaults.baseURL = "https://develop.goodlevelup.com";

export const api = {
    login:'/admin-api/v1/admins/login', //登入
    postClassList:'/api/v1/classes/list', //文章分類列表
    postList:'/api/v1/posts/list', //文章列表
    tagList:'/api/v1/tags/calc-item', //標籤列表
    postsInfo:'/api/v1/posts/info', //文章內容
    postRecommend:'/api/v1/posts/recommend', //推薦文章
    postMessages:'/api/v1/messages', //新增文章留言
    postMesList:'/api/v1/messages/list',//文章留言清單
    googleAdArea:'/api/v1/googles/info',//前台顯示廣告區塊

    //後台
    adminClassSelect: '/admin-api/v1/auth/classes/option', //分類選單用
    editClass:'/admin-api/v1/auth/classes', //分類-新增/編輯/刪除
    adminPostList:'/admin-api/v1/auth/posts/list', //文章列表
    adminPostGet:'/admin-api/v1/auth/posts/info', //文章內容讀取
    adminPostEdit:'/admin-api/v1/auth/posts', //文章內容編輯/新增
    getTagList:'/admin-api/v1/auth/tags/list', //標籤清單
    optionTagList:'/admin-api/v1/auth/tags/option', //標籤選單
    editTag:'/admin-api/v1/auth/tags', //標籤新增/編輯/刪除
    adminFilesList:'/admin-api/v1/auth/files/list', //檔案(圖片)列表
    adminFilesUrl:'/admin-api/v1/auth/files/get-s3-signed-url', //取得檔案通行url
    adminFileVerify:'/admin-api/v1/auth/files/verify', //驗證檔案及補充檔案資料
    adminFileEdit:'/admin-api/v1/auth/files', //編輯圖片
    adminMsgList:'/admin-api/v1/auth/messages/list',//留言列表
    adminMsgEdit:'/admin-api/v1/auth/messages', //編輯.刪除留言
    adminVisitorReport:'/admin-api/v1/auth/overviews/visitor-report', //瀏覽人次月報表
    adminGoogleAd:'/admin-api/v1/auth/googles', //新增/編輯/刪除google廣告
    adminGoogleAdList:'/admin-api/v1/auth/googles/list', //google廣告清單
    adminGoogleAdText:'/admin-api/v1/auth/googles/info' //google廣告內容
}

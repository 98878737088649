<template>
  <div class="PostPage">
    <div class="card mb-4">
      <div class="p-4 text-start">
        <div id="testArea">9999999999</div>
        <h3>{{postShowData.data.title}}</h3>
        <div class="postTime pt-2 pb-4"><el-icon class="me-2"><Calendar /></el-icon>{{postShowData.data.createdAt.slice(0,10)}}
          <span class="postViews"><el-icon class="me-2"><DataAnalysis /></el-icon>瀏覽人數:{{postShowData.data.visitors}}</span></div>
        <div class="htmlArea" v-html="postShowData.data.content"></div>
        <div class="aboutMeArea mt-5">
          <h5>關於站主</h5>
          <h4>Shiro</h4>
          <div>
            因為興趣無限擴張，一直很猶豫要不要寫一個很雜的Blog，後來還是這麼做了。<br/>
            <br/>
            聯絡：shiro050102\\小老鼠\\gmail.com<br/>
            <a href="https://p.ecpay.com.tw/80CEF3A" target="_blank">
              <el-button type="primary" class="mt-2" plain><el-icon class="me-1"><CoffeeCup /></el-icon>請我喝杯咖啡</el-button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="p-4 text-start">
        <h4><el-icon><FolderOpened /></el-icon> 相關文章</h4>
        <ul class="recommendList">
          <li v-for="item in postShowData.recommend" :key="item">
            <div class="itemPost" v-if="item.title !== postShowData.data.title">
              <div class="imgArea">
                <div class="imgBg" v-if="item.previewImage === ''" :style="{ backgroundImage: 'url(' + imageRecommend + ')'}"></div>
                <div class="imgBg" v-if="item.previewImage !== ''" :style="{ backgroundImage: 'url(' + item.previewImage + ')'}"></div>
              </div>
              <div class="textArea">
                <h6 style="font-size: 110%;"><span @click="recommendLink(item)">{{item.title}}</span></h6>
                <div>{{item.content}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card mb-5">
      <div class="p-4 text-start">
        <h4><el-icon><ChatLineSquare /></el-icon> 留言</h4>
        <div v-if="messageList.data === []" class="pt-2">
          本文章目前無留言
        </div>
        <div v-for="item in messageList.data" :key="item" class="pt-3 pb-3 messageList">
          <table class="nameArea">
            <tr>
              <td style="font-size: 1.3rem;">{{item.name}}</td>
              <td class="colorText2 small time">
                <el-popover v-if="item.email !== ''" placement="top" :width="200" trigger="click"
                            :content="item.email">
                  <template #reference>
                    <div class="mailIcon"><el-icon><Message /></el-icon></div>
                  </template>
                </el-popover>
                <span>{{item.createdAt.slice(0, 16)}}</span>
              </td>
            </tr>
          </table>
          {{item.content}}

          <div class="ps-5" v-if="item.children !== []">
            <div class="messageChildren pt-4" v-for="item in item.children" :key="item">
              <table class="nameArea">
                <tr>
                  <td style="font-size: 1.3rem;">{{ item.name }}</td>
                  <td class="colorText3 small time">
                    <el-popover placement="top" :width="200" trigger="click"
                                :content="item.email">
                      <template #reference>
                        <div class="mailIcon"><el-icon><Message /></el-icon></div>
                      </template>
                    </el-popover>
                    <span>{{item.createdAt.slice(0, 16)}}</span>
                  </td>
                </tr>
              </table>
              {{item.content}}
            </div>
          </div>
          <div class="text-end pt-2">
            <el-button @click="item.open = !item.open; isNewMsgModel = !isNewMsgModel">
              <span v-if="isNewMsgModel===true">回應</span>
              <span v-if="isNewMsgModel===false" style="display: flex;"><el-icon><CircleClose /></el-icon><span>關閉回應</span></span>
            </el-button>
            <div class="respondBox" v-if="item.open === true">
              <table class="w-100 mt-3">
                <tr>
                  <td class="pe-2">名字</td>
                  <td><el-input v-model="newMessageInput.data.name" /></td>
                </tr>
                <tr>
                  <td colspan="2" style="height: 1rem"></td>
                </tr>
                <tr>
                  <td class="pe-2">e-mail</td>
                  <td><el-input v-model="newMessageInput.data.mail" /></td>
                </tr>
                <tr>
                  <td colspan="2" style="height: 1rem"></td>
                </tr>
                <tr>
                  <td class="pe-2" style="vertical-align: top">留言內容</td>
                  <td><el-input v-model="newMessageInput.data.content" :rows="4" type="textarea"/></td>
                </tr>
              </table>
              <div class="mt-4">
                <el-button type="primary" @click="instance_vueRecaptchaV3.executeRecaptcha('respond', item.id)">送出留言</el-button>
              </div>
            </div>
          </div>
        </div>
<!--        {-->
<!--        "isAdmin":false, //是否為管理者回應-->
<!--        }-->

        <div v-if="isNewMsgModel===true" style="">
          <h4 class="mt-5"><el-icon><Edit /></el-icon> 新增留言</h4>
          <table class="w-100 mt-3">
            <tr>
              <td>名字</td>
              <td><el-input v-model="newMessageInput.data.name" /></td>
            </tr>
            <tr>
              <td colspan="2" style="height: 1rem"></td>
            </tr>
            <tr>
              <td>e-mail</td>
              <td><el-input v-model="newMessageInput.data.mail" /></td>
            </tr>
            <tr>
              <td colspan="2" style="height: 1rem"></td>
            </tr>
            <tr>
              <td style="vertical-align: top">留言內容</td>
              <td><el-input v-model="newMessageInput.data.content" :rows="4" type="textarea"/></td>
            </tr>
          </table>
          <div class="mt-4">
            <el-button type="primary" @click="instance_vueRecaptchaV3.executeRecaptcha('new', 0)">送出留言</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, onMounted, reactive, ref, watch} from "vue";
import {Calendar, DataAnalysis,FolderOpened, ChatLineSquare, CoffeeCup, Edit, CircleClose, Message}  from "@element-plus/icons-vue";
import axios from "axios";
import {api} from "@/api/api";
import imgBg from '../../assets/images/no-img.png'
import router from "@/router";
import {useRoute} from "vue-router";
import {ElMessage} from "element-plus";
import { useReCaptcha } from 'vue-recaptcha-v3';
export default {
  name: "PostPage",
  title:'123',
  components:{ Calendar, DataAnalysis, FolderOpened, ChatLineSquare, CoffeeCup, Edit, CircleClose, Message},
  setup(){
    const route = useRoute()
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    let postShowData = reactive({
      data:{
        id:0, //文章ID
        title:'',//文章標題
        createdAt:'', //文章發布時間
        visitors:0, //瀏覽人次
        content:'', //文章完整內文 html
        keywords:'', //搜尋引擎用關鍵字
        status: 1, //1=公開, 2=草稿
        class:[], //文章分類英文(階層1.階層2.階層3, 最少一層)
      },
      recommend:[] //推薦文章列表
    })

    let imageRecommend = imgBg




    //
    // postShowData.data = {
    //   id:1, //文章ID
    //   createdAt: "2023/12/12 15:15:24", //文章儲存時間
    //   title: "文章標題文章標題文章標題", //文章標題
    //   class:["travel","Japan","suburbs"], //文章分類英文(階層1.階層2.階層3, 最少一層)
    //   classTw:["旅遊","日本","郊區"], //文章分類中文(階層1.階層2.階層3, 最少一層)
    //   postName:"my-travel", //文章網址結尾(網址結構：/class/postName)
    //   tag:["2023New","日本輕旅行"], //文章tag清單
    //   visitors:100, //瀏覽人次
    //   status: 1, //1=公開, 2=草稿
    //   content: "string", //文章完整內文 html
    //   image:"",//文章列表中預覽圖網址
    //   keywords:"2023日旅最佳選" //搜尋引擎用關鍵字
    // }

    //路徑 麵包屑
    let BreadLink = reactive({
      data:{
        linkGroup:[]
      }
    })
    BreadLink.data = inject('getBreadLink')

    //歷史留言
    let messageList = reactive({
      data:[]
    })

    //取文章內容
    function getPostContent(){
      window.scrollTo(0, 0);
      const href =window.location.href
      const getLink = href.split("/post/")
      const classList = getLink[1].split("/")
      const countRecommend = 5 //推薦文章數筆數
      const contentNum = 20 //推薦文章前幾個字

      let apiLink= api.postsInfo //取得文章內容api
      let apiRecommend = api.postRecommend //取得推薦文章api
      //分類一層
      if (classList.length===2){
        apiLink = apiLink + '?class[]='+classList[0]+ '&postName='+classList[1]
        apiRecommend = apiRecommend+'?class[]='+classList[0] + '&count=' +countRecommend + '&contentNum=' + contentNum
      }
      //分類兩層
      if (classList.length===3){
        apiLink = apiLink + '?class[]='+classList[0]+'&class[]='+classList[1]+ '&postName='+classList[2]
        apiRecommend = apiRecommend+'?class[]='+classList[0]+'&class[]='+classList[1] + '&count=' +countRecommend+ '&contentNum=' + contentNum
      }
      axios.get(apiLink).then(res =>{
        if (res.data.msg === "success"){
          //判斷是否為隱藏文章
          if (res.data.data.status !== 1){
            PageNotFound()
          }else {
            //公開文章
            postShowData.data = res.data.data
            document.title = postShowData.data.title + '｜'+ window.g.webName

            for (let i = 0; i < postShowData.data.class.length; i++) {
              BreadLink.data.linkGroup.push({class: postShowData.data.class[i], name: postShowData.data.classTw[i]})
            }

            const AdText = /\['AD',.*?\]/
            const idName = 'adArea1'

            axios.get(api.googleAdArea+'?id=2').then(res => {
              if (res.data.msg ===  "success"){
                postShowData.data.content = postShowData.data.content.replace(AdText , '<div id='+idName+'>'+res.data.data.code+'</div>')
              }
            })

          }
        }
      })



      //前往404頁面
      function PageNotFound(){
        router.push({name:'PageNotFound'})
      }


      //取得推薦文章
      axios.get(apiRecommend).then(res => {
        //文章太少時不要取到第二層  取一層分類就好
        if (classList.length===3 && postShowData.data.class.length < countRecommend){
          const apiRecommend2 = api.postRecommend+'?class[]='+classList[0] + '&count=' +countRecommend + '&contentNum=' + contentNum
          axios.get(apiRecommend2).then(res => {
            postShowData.recommend = res.data.data
          })
        }else {
          postShowData.recommend = res.data.data
        }
      })

      getNowMsg()

    }
    getPostContent()

    //取得留言清單
    function getNowMsg(){
      const href =window.location.href
      const getLink = href.split("/post/")
      const classList = getLink[1].split("/")
      let msgApi = api.postMesList + '?pageSize=1000&currentPage=1&'
      //分類一層
      if (classList.length===2){
        msgApi = msgApi + 'class[]='+classList[0]+ '&postName='+classList[1]
      }
      //分類兩層
      if (classList.length===3){
        msgApi = msgApi + 'class[]='+classList[0]+'&class[]='+classList[1]+ '&postName='+classList[2]
      }
      axios.get(msgApi).then(res => {
        if (res.data.msg === "success"){
          messageList.data = res.data.data.dataList
          for (let i=0; i < messageList.data.length; i++){
            messageList.data[i].open = false
          }
        }
      })
    }


    //推薦文章的連結產生
    function recommendLink(item){
      if (item.class.length===1){
        router.push({path:'/post/'+item.class[0]+ '/' +item.postName})
      }
      if (item.class.length===2){
        router.push({path:'/post/'+item.class[0]+'/'+item.class[1]+ '/' +item.postName})
      }
    }
    watch(() => route.path, (value) => {
      if (value.includes("/post/")){
        getPostContent()
      }
    })




    //新增留言
    let newMessageInput = reactive({
      data:{
        parentId: 0,
        class:[],
        postName:'',
        name:'',
        mail:'',
        content:'',
        reCaptchaToken:''
      }
    })
    function sendMessage(type, id){
      const href =window.location.href
      const getLink = href.split("/post/")
      const classList = getLink[1].split("/")
      newMessageInput.data.parentId = id
      //分類一層
      if (classList.length===2){
        newMessageInput.data.class = [classList[0]]
        newMessageInput.data.postName = classList[1]
      }
      //分類兩層
      if (classList.length===3){
        newMessageInput.data.class = [classList[0], classList[1]]
        newMessageInput.data.postName = classList[2]
      }
      axios.post(api.postMessages, newMessageInput.data).then(res=>{
        ElMessage(res.data.msg)
        getNowMsg()
        //清空留言內容
        newMessageInput.data.name = ''
        newMessageInput.data.mail = ''
        newMessageInput.data.content = ''
      })
    }

    let isNewMsgModel = ref(true) //是否在新增留言模式

    //機器人認證
    const instance_vueRecaptchaV3 = reactive({
      executeRecaptcha: async (type, id) => {
        // (可選) 等待直到 recaptcha 載入完成
        await recaptchaLoaded();
        // 執行 "login" 狀態的 reCAPTCHA
        //login 登入用 / social留言用 / e-commerce商品交易
        const token = await executeRecaptcha('social').catch((err) => {
          console.log(err)
        });
        // 後續傳給後端進行認證
        if (token){
          newMessageInput.data.reCaptchaToken = token
          sendMessage(type, id)
        }else {
          console.log('null')
        }
      },
    })

    //測試


    function changeText(){
      let nowTest = document.getElementById("testArea")

      if (nowTest){
        nowTest.innerHTML = 'Hello bunny!!'
      }


    }


    onMounted(() => {
      changeText()
      window.addGoogleAds()
    })

    return{
      postShowData,
      imageRecommend,
      recommendLink,
      newMessageInput,
      sendMessage,
      messageList,
      isNewMsgModel,
      instance_vueRecaptchaV3
    }
  }
}
</script>

<style scoped>

</style>

<template>
<div class="mainTheme">
  <div class="titleArea">
    <div class="menuArea">
      <div class="adArea">
<!--        廣告區域-->
        <div class="addGoogleItem">
          <ins class="adsbygoogle" style="display: block" data-ad-client="ca-pub-8674562907161907" data-ad-slot="3181911273" data-ad-format="auto" data-full-width-responsive="true"></ins>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">{{webName}}</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li class="nav-item" v-for="item in menuList.data" :key="item" :class="item.children.length>0?'dropdown':''">
                <router-link v-if="item.children.length===0" class="nav-link" aria-current="page" :to="menuLink1(item)" :class="menuClass1(item)">{{item.classTw}}</router-link>
                <router-link v-if="item.children.length>0" class="nav-link" aria-current="page" :to="menuLink1(item)" :class="menuClass1(item)" data-bs-toggle="dropdown" aria-expanded="false">{{item.classTw}}</router-link>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown" v-if="item.children.length>0">
                  <li v-for="child in item.children" :key="child">
                    <router-link class="dropdown-item" :to="menuLink2(item, child)">{{child.classTw}}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="mainPageArea">
    <div class="container">
      <bread-area/>
      <div class="rowMain">
        <div class="pageContentArea pe-lg-4">
          <router-view />
        </div>
        <div class="rightContentArea">
          <div class="card">
            <div class="card-body text-start">
              <h5 style="font-size: 110%">文章分類</h5>
              <el-tree
                  :data="menuList.data"
                  node-key="id"
                  :props="defaultProps"
                  default-expand-all
                  :expand-on-click-node=false
                  @node-click="treeCheck"
              />
              <h5 class="mt-5" style="font-size: 110%">標籤</h5>
              <router-link  v-for="item in menuList.tag" :key="item" :to="'/class/tag/'+item.tag">
                <el-tag class="m-1" type="info" effect="plain">
                  {{ item.tagTw }}
                </el-tag>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footerArea">
    <div class="text-center small">Copyright © 2024 {{webName}} All Rights Reserved.</div>
    <div class="text-center small mt-2"><router-link :to="{name:'adminLoginPage'}" style="color: rgba(255,255,255,0.4)">Shiro專用</router-link></div>
    <div class="text-center small mt-2">本站採用 reCAPTCHA 保護機制 <a href="https://policies.google.com/privacy">隱私權</a> &amp; <a href="https://policies.google.com/terms">條款</a></div>
  </div>
</div>
</template>

<script>
import {onMounted, provide, reactive, ref, watch} from "vue";
import BreadArea from "@/views/post/breadcrumb.vue";
import {useRoute} from "vue-router";
import axios from "axios";
import {api} from "@/api/api";
import router from "@/router";

export default {
  name: "main-theme",
  components: {BreadArea},
  setup(){
    const route = useRoute()
    let menuList = reactive({
      data:[],
      tag:[]
    })
    const defaultProps = {
      children: 'children',
      label: 'classTw',
      class:'class'
    }

    //路徑 麵包屑
    let BreadLink = reactive({
      data:{
        linkGroup:[]
      }
    })
    provide('getBreadLink', BreadLink.data)

    //文章列表取得參數
    let showDataList = reactive({
      settings:{
        totalCount:0, //文章總數
        currentPage:1, //目前所在頁數
        pageSize:20, //每頁文章數量
        contentNum:60, //撈出文章前多少字元
        searchTitle:'', //標題關鍵字
        sort:'time', //排序方式(時間time/總瀏覽人次數views)
        sortType:'DESC', //升序ASC/降序DESC
        tag:'' //文章標籤
      }
    })
    provide('setShowDataList', showDataList.settings)

    function getBread(){
      netLink.value = window.location.href
      BreadLink.data.linkGroup = []

      //分類列表
      if (netLink.value.includes('/class/') && !netLink.value.includes('/tag/')){
        const getLink = netLink.value.split("/class/")
        let childrenList = ref([])
        let childrenListSon = ref([])

        //麵包屑名稱與連結
        if (getLink[1]!==''){
          const linkList = getLink[1].split("/")
          for (let i=0; i < linkList.length; i++){
            BreadLink.data.linkGroup.push({class: linkList[i], name:linkList[i]})
            if (i === 0){
              const getItem0 = menuList.data.filter((item) => {return item.class===linkList[0]});
              if (getItem0.length>0){
                BreadLink.data.linkGroup[0].name = getItem0[0].classTw
                childrenList.value = getItem0[0].children
              }
            }
            if (i === 1){
              const getItem0 = childrenList.value.filter((item) => {return item.class===linkList[1]});
              if (getItem0.length>0){
                BreadLink.data.linkGroup[1].name = getItem0[0].classTw
                childrenListSon.value = getItem0[0].children
              }
            }
            if (i === 2){
              const getItem0 = childrenListSon.value.filter((item) => {return item.class===linkList[2]});
              if (getItem0.length>0){BreadLink.data.linkGroup[2].name = getItem0[0].classTw }
            }
          }
        }
      }
      //標籤列表
      if (netLink.value.includes('/tag/')){
        const getLink = netLink.value.split("/tag/")
        BreadLink.data.linkGroup.push({class: getLink[1], name:getLink[1]})
        showDataList.settings.tag = getLink[1]
      }
    }
    let netLink = ref('')
    netLink.value = window.location.href

    watch(() => route.path, () => {
      getBread()
    })

    // 父層class
    function menuClass1(item){
      let className
      if (item.children.length>0){
        className = 'dropdown-toggle'
      }

      return className
    }
    // 父層link
    function menuLink1(item){
      return '/class/'+item.class
    }
    //子層link
    function menuLink2(item, child){
      return '/class/'+item.class+'/'+child.class
    }

    //點了樹狀分類連結
    function treeCheck(node, data){
      showDataList.settings.tag =''
      if (data.level===1){
        router.push({ path: '/class/'+data.data.class })
      }
      if (data.level===2){
        router.push({ path: '/class/'+data.parent.data.class+'/'+data.data.class})
      }
      if (data.level===3){
        router.push({ path: '/class/'+data.parent.parent.data.class+'/'+data.parent.data.class+'/'+data.data.class})
      }
      //回到座標0
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }

    //讀取頁面資料
    function startPage(){
      menuList.data = []
      //讀取分類
      axios.get(api.postClassList).then(res=>{
        if (res.data.msg === "success"){
          let getData  = res.data.data

          for (let i=0; i<getData.length; i++){
            const haveSon = getData[i]
            haveSon.parentId = 0
            menuList.data.push(haveSon)
          }
        }
      })
      //讀取標籤
      axios.get(api.tagList).then(res => {
        if (res.data.msg === "success"){
          menuList.tag = res.data.data
        }
      })
      getBread()
    }
    startPage()

    const webName = window.g.webName //網站名稱

    onMounted(() => {
      //插入廣告
      const oScript = document.createElement('script');
      oScript.type = 'text/javascript';
      oScript.async = true
      oScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8674562907161907';
      oScript.crossOrigin  = "anonymous"
      document.head.appendChild(oScript);

      window.addGoogleAds()
    })

    return{
      menuList,
      menuClass1,
      menuLink1,
      menuLink2,
      defaultProps,
      treeCheck,
      showDataList,
      webName
    }
  }
}
</script>

<style scoped>

</style>

export default class MyUploadAdapter {
    constructor( loader ) {
        this.loader = loader;
    }

    async upload() {
        const data = new FormData();
        data.append('files', await this.loader.file);

        // return new Promise((resolve, reject) => {
        //     axios({
        //         url: `请求地址`,
        //         method: 'post',
        //         data,
        //         headers: {
        //             'token': token // 此处为你定义的token 值(Bearer token 接口认证方式)
        //         },
        //         withCredentials: true // true 为不允许带 token, false 为允许，可能会遇到跨域报错：Error: Network Error 弹窗提示
        //     }).then(res => {
        //         console.log(res)
        //         var resData = {}
        //         resData.default = res.url
        //         //注意这里的resData一定要是一个对象，而且外面一定要有一个default设置为图片上传后的url，这是ckeditor的规定格式
        //         resolve(resData);
        //     }).catch(error => {
        //         reject(error)
        //     });
        //
        //
        // });
        const resData = {
            default:'https://file-develop.s3.ap-northeast-1.amazonaws.com/test/c053c708-bac0-4171-8c06-9e7eb6c083ca.jpeg'
        }
        console.log(resData)
        return resData
    }
}

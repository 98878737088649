<template>
<div class="AdminTheme">
  <div class="common-layout">
    <el-container>
      <el-header class="text-start small">
        <router-link :to="{name:'PostIndex'}">回首頁</router-link>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu class="adminMainMenu" :default-active="$route.path"  router >
            <el-menu-item index="/level-admin/dashboard"><el-icon><Menu /></el-icon>儀錶板</el-menu-item>
            <el-sub-menu index="1">
              <template #title>
                <el-icon><Menu /></el-icon>
                <span>文章</span>
              </template>
              <el-menu-item index="/level-admin/admin-post-list">文章列表</el-menu-item>
              <el-menu-item index="/level-admin/admin-class-list">分類</el-menu-item>
              <el-menu-item index="/level-admin/admin-tag-list">標籤</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="/level-admin/admin-img-list"><el-icon><Menu /></el-icon>圖片管理</el-menu-item>
            <el-menu-item index="/level-admin/admin-msg-list"><el-icon><Menu /></el-icon>留言</el-menu-item>
            <el-menu-item index="/level-admin/admin-ad-list"><el-icon><Menu /></el-icon>區塊管理</el-menu-item>

          </el-menu>
        </el-aside>
        <el-main class="text-start">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import {Menu} from '@element-plus/icons-vue'
export default {
  name: "AdminTheme",
  components:{Menu}
}
</script>

<style scoped>

</style>

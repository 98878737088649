<template>
<div class="AdmintagList">
  <div class="row">
    <div class="col-12">
      <h3>標籤</h3>
    </div>
    <div class="col-5">
      <h5>新增標籤</h5>
      <div class="mt-3">名稱</div>
      <el-input v-model="newBody.data.tagTw" />
      <div class="mt-3">代稱</div>
      <el-input v-model="newBody.data.tag" />
      <div class="mt-4">
        <el-button type="primary" @click="addClass">新增標籤</el-button>
      </div>
    </div>
    <div class="col-7">
      <h5>現有標籤</h5>
      <table class="w-100 adminTable">
        <thead>
          <tr>
            <td>名稱</td>
            <td>代稱</td>
            <td>排序</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tagList.data" :key="item">
            <td>{{item.tagTw}}</td>
            <td>{{item.tag}}</td>
            <td>
              <div v-if="tagList.data.length>1">
              <el-button :icon="Top" circle @click="orderChange('up', item)" v-if="index>0"/>
              <el-button :icon="Bottom" circle @click="orderChange('down', item)" v-if="index !== tagList.data.length-1"/>
              </div>
            </td>
            <td>
              <el-button type="info" plain  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(0, item)">編輯</el-button>
              <el-button type="danger" plain  data-bs-toggle="modal" data-bs-target="#pageModal" @click="openModel(1, item)">刪除</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- 編輯/刪除Modal -->
  <div class="modal fade" id="pageModal" tabindex="-1"  aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content" v-if="modelType===0">
        <div class="modal-header">
          <h5 class="modal-title" >編輯分類: id {{editBody.data.id}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mt-3">名稱</div>
          <el-input v-model="editBody.data.tagTw" />
          <div class="mt-3">代稱</div>
          <el-input v-model="editBody.data.tag" />
          <div class="mt-3">排序</div>
          <el-input v-model="editBody.data.order" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="editClass">送出</button>
        </div>
      </div>
      <div class="modal-content" v-if="modelType===1">
        <div class="modal-header">
          <h5 class="modal-title" >刪除分類</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          請確認是否刪除「{{deleteClassTw}}」此分類?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteClass">刪除</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {reactive, ref} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";
import { Top,Bottom} from "@element-plus/icons-vue";
export default {
  name: "AdminTagList",
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    //現有的標籤
    let tagList = reactive({
      data:[]
    })

    function startPage(){
      tagList.data =[]

      axios.get(api.getTagList+'?pageSize=1000&currentPage=1', {headers: {Authorization: 'Bearer '+adminToken}}).then(res=>{
        if (res.data.msg === "success"){
          let getData  = res.data.data.dataList

          for (let i=0; i<getData.length; i++){
            const haveSon = getData[i]
            tagList.data.push(haveSon)
          }
        }
      })
    }
    startPage()

    //新增分類
    let newBody = reactive({
      data:{
        tag:"", //tag
        tagTw:"", //tag中文
        order:1 //排序
      }
    })

    function addClass(){
      axios.post(api.editTag, newBody.data, {headers: {Authorization: 'Bearer '+adminToken}}).then(res => {
        if (res.data.msg === "success"){
          ElMessage.success('新增成功')
          startPage()
        }else {
          ElMessage.error('新增失敗')
        }
      })
    }

    let modelType = ref(0) //0.編輯/1.刪除

    //刪除分類
    let deleteBody = ref() //刪除時送出內容
    let deleteClassTw = ref() //刪除提示刪除哪個分類
    function deleteClass(){
      axios.delete(api.editTag,{headers: {Authorization: 'Bearer ' + adminToken},data:deleteBody.value}).then((res)=>{
        if (res.data.msg === "success"){
        ElMessage.success('刪除成功')
        startPage()
        }
      })
    }

    //編輯分類
    let editBody = reactive({
      data:{
        id:0,
        tag:"", //tag
        tagTw:"", //tag中文
        order:1  //排序
      }
    })
    function editClass(){
      axios.patch(api.editTag,editBody.data,{ headers: { 'Authorization': 'Bearer ' + adminToken }, }
      ).then((res) => {
        if (res.data.msg === "success"){
          ElMessage.success('編輯成功')
          startPage()
        }
      })
    }

    //打開跳窗
    function openModel(type, item){
      modelType.value = type

      //編輯
      if (type === 0){
        editBody.data.id = item.id
        editBody.data.tag = item.tag
        editBody.data.tagTw = item.tagTw
        editBody.data.order = item.order
      }
      //刪除
      if (type === 1){
        deleteBody.value = {id:item.id}
        deleteClassTw.value = item.tagTw
      }
    }

    //改變排序
    function orderChange(type, item){
      editBody.data.id = item.id
      editBody.data.tag = item.tag
      editBody.data.tagTw = item.tagTw
      editBody.data.order = item.order

      if (type === 'up' && item.order !== 0 && item.order !== 1){
        editBody.data.order = editBody.data.order - 1
        editClass()
      }
      if (type === 'down'){
        editBody.data.order = editBody.data.order + 1
        editClass()
      }
    }



    return {
      tagList,
      newBody,
      addClass,
      modelType,
      openModel,
      deleteClass,
      deleteClassTw,
      editBody,
      editClass,
      Top, Bottom,
      orderChange
    }
  }
}
</script>

<style scoped>

</style>

<template>
<div class="Dashboard">
  <div class="row">
    <div class="col-12">
      <h3>儀錶板</h3>
    </div>
    <div class="col-lg-5">
      <h5>文章流量</h5>
      <div ref="chartPic" style="width:100%;height:300px;"></div>
      <table class="w-100 changeMonth">
        <tr>
          <td><a class="changeLink" @click="goNextMonth('p')">←前一月</a></td>
          <td class="text-center">{{nowMonthText.slice(0,4)}}年 {{nowMonthText.slice(4,6)}}月</td>
          <td class="text-end"><a class="changeLink" @click="goNextMonth('n')">下一月→</a></td>
        </tr>
      </table>
      <table class="w-100 mt-3 TrafficSorting">
        <thead>
        <tr>
          <td style="width: 3rem">排序</td>
          <td>文章</td>
          <td style="width: 5rem; text-align: right;">瀏覽人次</td>
        </tr>
        </thead>
        <tbody v-if="getMonthData.data.top10.length > 0">
        <tr v-for="item in getMonthData.data.top10" :key="item">
          <td>{{item.index}}</td>
          <td><router-link :to="monthDataLink(item)">{{item.title}}</router-link></td>
          <td class="text-end" style="color: var(--color1);"><strong>{{item.visitors}}</strong></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>

import {onMounted, reactive, ref, watch} from "vue";
import * as echarts from "echarts";
import axios from "axios";
import {api} from "@/api/api";

export default {
  name: "dashboardPage",
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    //圖表設定
    let List1 = ref([1, 2, 3, 4]) //日期
    let List2 = ref( [10, 15, 20, 8]) //每日瀏覽量

    let nowMonthText = ref('') //顯示現在月份
    let nowMonthNum = ref(0) //0為目前月份 -1前一月份 1下一月份

    //圖表顯示內容
    let getMonthData =reactive({
      data:{
        top10:[], //本月排行
        dayReport:[] //每日瀏覽數
      }
    })

    //取得月份最後一天是幾號
    function getMonth(months) {
      let d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      if (Math.abs(months) > 12) {
        months = months % 12
      }
      if (months !== 0) {
        if (month + months > 12) {
          year++
          month = (month + months) % 12
        } else if (month + months < 1) {
          year--
          month = 12 + month + months
        } else {
          month = month + months
        }
      }

      month = month < 10 ? "0" + month: month;
      let lastday = "";

      month = month.toString()
      nowMonthText.value = year.toString()+month.toString()
      if (month === "01" || month === "03" || month === "05" || month === "07" || month === "08" || month === "10" || month === "12") {
        lastday = 31
      } else if (month === "02") {
        if ((year % 4 === 0 && year % 100 !== 0) || (year % 100 === 0 && year % 400 === 0)) {
          lastday = 29
        } else {
          lastday = 28
        }
      } else {
        lastday = 30
      }
      let day = ""
      day = lastday
      return day
    }

    //當月最後一天幾號
    function chartUpdated(){
      const endDay = getMonth(nowMonthNum.value) //每月有幾天
      console.log(endDay)
      List1.value = []
      List2.value = []
      const getLink = api.adminVisitorReport + '?filterTime=' + nowMonthText.value
      axios.get(getLink, {headers: {Authorization: 'Bearer '+adminToken}}).then(res => {
        if ( res.data.msg === "success"){
          getMonthData.data = res.data.data
          const dayReport = getMonthData.data.dayReport
          for (let i = 1; i < dayReport.length; i++){
            List1.value.push(dayReport[i].date)
            List2.value.push(dayReport[i].allVisitors)
          }
        }
      })
    }
    chartUpdated()

    //前一月/後一月
    function goNextMonth(type){
      if (type === 'p'){
        nowMonthNum.value = nowMonthNum.value - 1
      }else {
        nowMonthNum.value = nowMonthNum.value + 1
      }
      chartUpdated()
    }

    const chartPic = ref(null)
    let optionValue = {
      grid: {
        left: 70
      },
      xAxis: {
        type: '',
        data: List1.value,
        name: '日期',
        nameLocation: 'center',
        nameGap: 25
      },
      yAxis: {
        type: 'value',
        name: '瀏覽人次',
        nameLocation: 'center',
        nameGap: 50
      },
      tooltip: {
        trigger: 'axis',
        formatter: "日期： {b0}<br/>瀏覽人次: {c0}"
      },
      series: [
        {
          data: List2.value,
          type: 'bar'
        }
      ]
    }

    //前往文章前台頁面
    function monthDataLink(item){
      let link = '/post'
      for (let i = 0; i < item.class.length; i++){
        link = link + '/' + item.class[i]
      }
      link = link  + '/' + item.postName
      return link
    }

    function chart(){
      chartPic.value.setAttribute("_echarts_instance_", "")
      let myChart = echarts.init(chartPic.value)
      myChart.setOption(optionValue)
      window.addEventListener("resize", () => {
        myChart.resize()
      })
    }

    onMounted(() => {
      chart()
    })


    watch(() => List2.value, (newList2) => {
      optionValue.xAxis.data = List1.value
      optionValue.series[0].data = newList2
      chart()
    }, { deep: true })

    return{
      goNextMonth,
      nowMonthText,
      chartPic,
      getMonthData,
      monthDataLink
    }
  }
}
</script>

<style scoped>

</style>

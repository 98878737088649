import axios from "axios";
import {api} from "@/api/api";
import {ElMessage} from "element-plus";

export function ImgVerify(adminToken, getData, resValue, PictureData){
    return new Promise((resolve, reject) => {
        if (resValue.value.statusText === 'OK') {
            const patchBody = {
                filesId: getData.value.filesId, // 從檔案清單或是S3檔案上傳拿到的id
                title: PictureData.data.title, // 標題
                attributeText: PictureData.data.attributeText, // 替代文字
                instructions: PictureData.data.instructions, // 說明文字
                content: PictureData.data.content // 內容說明
            }
            console.log('api.adminFileVerify')
            axios.patch(api.adminFileVerify, patchBody, {headers: {Authorization: 'Bearer ' + adminToken}}).then((res) => {
                console.log('api.adminFileVerify End')
                if (res.data.msg === "success") {
                    ElMessage.success('上傳成功')
                    resolve()
                } else {
                    ElMessage.error('上傳失敗')
                    reject()
                }
            })
        }
    })
}

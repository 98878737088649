import { createRouter, createWebHistory } from 'vue-router'
// import {createRouter, createWebHashHistory} from 'vue-router'
import PostList from './views/post/post-list.vue'
import mainTheme from "@/views/main-theme.vue";
import dashboardPage from '@/views/admin-data/dashboard.vue'
import AdminTheme from '@/views/admin-theme.vue'
import PostIndex from '@/views/post/post-index.vue'
import PostPage from "@/views/post/post-page.vue";
import adminLoginPage from '@/views/admin-login.vue'
import AdminClassList from "@/views/admin-post/admin-class-list.vue";
import AdminPostList from '@/views/admin-post/admin-post-list.vue'
import AdminPostEdit from "@/views/admin-post/admin-post-edit.vue";
import AdminTagList from '@/views/admin-tag/admin-tag-list.vue'
import AdminImgList from '@/views/admin-img/admin-img-list.vue'
import AdminMsgList from "@/views/admin-msg/admin-msg-list.vue";
import PageNotFound from '@/views/page/page404.vue'
import AdminAdList from '@/views/admin-ad/admin-ad-list.vue'

const routes = [
    {
        path: '/',
        name: 'mainTheme',
        component: mainTheme,
        children: [
            //文章列表
            {
                path:'/',
                component:PostIndex,
                name: 'PostIndex',
            },
            //文章列表-分類
            {
                path:'/class/:class*' ,
                component:PostList,
                name: 'PostList',
            },
            //文章內文
            {
                path:'/post/:class*' ,
                component:PostPage,
                name: 'PostPage',
                meta:{
                    title:''
                }
            },
        ]
    },
    //404找不到匹配資料
    {
        path:'/404',
        component:PageNotFound,
        name: 'PageNotFound',
    },
    //登入後台
    {
        path:'/up-admin-login' ,
        component:adminLoginPage,
        name: 'adminLoginPage',
        meta:{title:'登入後台'}
    },
    //文章編輯
    {
        path:'/post-edit' ,
        component:AdminPostEdit,
        name: 'AdminPostEdit',
        meta:{title:'文章編輯'}
    },
    //後台
    {
        path: '/level-admin/',
        name: 'AdminTheme',
        component: AdminTheme,
        meta:{title:'管理者後台'},
        children: [
            //儀錶板
            {
                path:'dashboard',
                component:dashboardPage,
                name: 'dashboardPage',
            },
            //文章列表
            {
                path:'admin-post-list',
                component:AdminPostList,
                name: 'AdminPostList',
            },
            //文章分類管理
            {
                path:'admin-class-list',
                component:AdminClassList,
                name: 'AdminClassList',
            },
            //文章tag管理
            {
                path:'admin-tag-list',
                component:AdminTagList,
                name: 'AdminTagList',
            },
            //圖片管理
            {
                path:'admin-img-list',
                component:AdminImgList,
                name: 'AdminImgList',
            },
            //留言管理
            {
                path:'admin-msg-list',
                component:AdminMsgList,
                name: 'AdminMsgList',
            },
            //區塊管理
            {
                path:'admin-ad-list',
                component:AdminAdList,
                name: 'AdminAdList',
            },

        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router

import axios from "axios";

export function uploadImg(adminToken, fileObj, getData, resValue){
    return new Promise((resolve, reject) => {
        console.log('uploadImg')
        console.log(getData.value)
        axios.put(getData.value.signedUrl, fileObj.file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            resValue.value = res
            if (resValue.value){
                console.log('uploadImg end')
                resolve(resValue)
            }else {
                reject()
            }
        })
    })
}

<template>
<div class="breadcrumb mt-3">

  <el-breadcrumb :separator-icon="ArrowRight">
    <el-breadcrumb-item :to="{ path: '/' }"><el-icon class="me-1"><HomeFilled /></el-icon>首頁</el-breadcrumb-item>
    <el-breadcrumb-item v-if="BreadLink.data.linkGroup.length>0" :to="{ path: '/class/'+ BreadLink.data.linkGroup[0].class }"><el-icon class="me-1"><Folder /></el-icon>{{BreadLink.data.linkGroup[0].name}}</el-breadcrumb-item>
    <el-breadcrumb-item v-if="BreadLink.data.linkGroup.length>1" :to="{ path: '/class/'+ BreadLink.data.linkGroup[0].class+'/'+BreadLink.data.linkGroup[1].class}"><el-icon class="me-1"><Folder /></el-icon>{{BreadLink.data.linkGroup[1].name}}</el-breadcrumb-item>
    <el-breadcrumb-item v-if="BreadLink.data.linkGroup.length>2" :to="{ path: '/class/'+ BreadLink.data.linkGroup[0].class+'/'+BreadLink.data.linkGroup[1].class+'/'+BreadLink.data.linkGroup[2].class}"><el-icon class="me-1"><Folder /></el-icon>{{BreadLink.data.linkGroup[2].name}}</el-breadcrumb-item>
  </el-breadcrumb>
</div>
</template>

<script>
import {inject, reactive} from "vue";
import {ArrowRight, HomeFilled, Folder} from "@element-plus/icons-vue";
export default {
  name: "BreadArea",
  components:{HomeFilled, Folder},
  setup(){


    //路徑 麵包屑
    let BreadLink = reactive({
      data:{
        linkGroup:[]
      }
    })
    BreadLink.data = inject('getBreadLink')

    return {
      ArrowRight,
      BreadLink
    }
  }
}
</script>

<style scoped>

</style>

<template>
<div class="adminLoginPage">
  <div class="container">
    <div class="ms-auto me-auto" style="max-width: 300px">
      <el-card class="box-card text-start">
        <div class="card-header">
          <span>通關處</span>
        </div>
        <div class="mt-3">
          <div>帳號</div>
          <el-input v-model="inputText.data.account" />
          <div class="mt-3">密碼</div>
          <el-input v-model="inputText.data.password"  />
          <div class="mt-4">
            <el-button type="primary" @click="sendLogin">登入</el-button>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</div>
</template>

<script>
import {reactive} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import router from "@/router";

export default {
  name: "adminLoginPage",
  setup(){
    let inputText = reactive({
      data:{
        account: "",
        password: ""
      }
    })

    function sendLogin(){
      axios.post(api.login, inputText.data).then(res=>{
        if (res.data.msg==="success"){
          sessionStorage.setItem("token", res.data.data.token)
          router.push({name:'dashboardPage'})
        }
      })
    }

    return{
      inputText,
      sendLogin
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="PostIndex">
    <!--  首頁  -->
   <post-list/>
  </div>
</template>

<script>
import PostList from "@/views/post/post-list.vue";

export default {
  name: "PostIndex",
  components: {PostList}
}
</script>

<style scoped>

</style>

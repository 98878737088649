<template>
<div class="AdminPostList">
  <table>
    <tr>
      <td><h3>文章</h3></td>
      <td class="ps-3">
        <div><el-button plain @click="editPage('new', '')"><el-icon><Plus /></el-icon>新增文章</el-button></div>
      </td>
    </tr>
  </table>
  <table class="mb-2 small">
    <tr>
      <td><a @click="changePostType(0)">文章({{ showDataList.totalCount }})</a></td>
      <td>｜</td>
      <td><a @click="changePostType(2)">草稿</a></td>
    </tr>
  </table>
  <table class="mb-2 w-100">
    <tr>
      <td style="width: 200px;">
        <el-select v-model="searchInput.data.class">
        <el-option
            v-for="item in classSelect.data"
            :key="item.class"
            :label="item.classTw"
            :value="item.class"
        />
      </el-select>
      </td>
      <td class="ps-2">
        <el-button plain @click="getNowPost">篩選</el-button>
      </td>

      <td class="text-end">
        每頁顯示：
        <el-select v-model="searchInput.data.pageSize" style=" width: 110px;">
          <el-option
              v-for="item in sizeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
              @click="getNowPost"
          />
        </el-select>
      </td>
    </tr>
  </table>
  <table class="w-100 adminTable">
    <thead>
    <tr>
      <td>ID</td>
      <td>顯示</td>
      <td>內容標題</td>
      <td>分類</td>
      <td>標籤</td>
      <td>瀏覽次</td>
      <td>編輯日期</td>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in showDataList.data" :key="item">
      <td>{{item.id}}</td>
      <td>
        <router-link :to="monthDataLink(item)" target="_blank"><el-icon v-if="item.status === 1"><View /></el-icon></router-link>
      </td>
      <td><a @click="editPage('edit', item)">{{item.title}}</a></td>
      <td>
        <el-tag v-for="item in item.classTw" :key="item" class="mx-1" type="info" effect="plain">
          {{ item }}
        </el-tag>
      </td>
      <td><div><span class="small me-2" v-for="item in item.tagTw" :key="item" style="display: flex; align-items: center;"><el-icon><CollectionTag /></el-icon><span class="ps-1">{{item}}</span></span></div></td>
      <td>{{item.visitors}}</td>
      <td class="small">{{item.createdAt}}</td>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import {Plus, CollectionTag, View} from "@element-plus/icons-vue";
import {reactive} from "vue";
import axios from "axios";
import {api} from "@/api/api";
import router from "@/router";
import {ClassSelectList} from '../class-select-list'
export default {
  name: "AdminPostList",
  components:{Plus, CollectionTag, View},
  setup(){
    const adminToken = sessionStorage.getItem("token") //取得token

    //顯示的文章列表
    let showDataList = reactive({
      data:{},
      totalCount:0
    })

    //取得選單用分類
    let classSelect = reactive({
      data:[]
    })

    const sizeList =[
      {name:'20筆/頁', value:20},
      {name:'50筆/頁', value:50},
      {name:'100筆/頁', value:100},
    ]

    //搜尋條件
    let searchInput = reactive({
      data:{
        class:'', //分類
        tag:'',//標籤
        pageSize:20, //每頁的文章數量
        currentPage:1, //當前頁數
        filterTitle:'', //搜尋標題關鍵字(可空字串)
        sort:'time', //排序條件(時間time/瀏覽人次數views)
        sortType:'DESC',//升序ASC/降序DESC
        status:0, //0=所有文章, 1=公開的文章, 2=草稿文章
        isGarbage:false //是否取得垃圾桶內的資料; true=是 false=否
      }
    })


    //取得分類列表
    ClassSelectList(classSelect.data, '', 3)

    //取得文章列表
    function getNowPost(){
      searchInput.data.currentPage = 1
      showDataList.data = []
      let getLink =api.adminPostList+'?pageSize='+searchInput.data.pageSize+'&currentPage='+searchInput.data.currentPage+'&sort='+searchInput.data.sort
          +'&sortType='+searchInput.data.sortType+'&status='+searchInput.data.status+'&isGarbage='+searchInput.data.isGarbage

      if (searchInput.data.class !==''){getLink = getLink + '&class='+searchInput.data.class}
      if (searchInput.data.tag !==''){getLink = getLink + '&tag='+searchInput.data.tag}
      if (searchInput.data.filterTitle !==''){getLink = getLink + '&filterTitle='+searchInput.data.filterTitle}

      axios.get(getLink, {headers: {Authorization: 'Bearer '+adminToken}}).then(res=>{
        if (res.data.msg === "success") {
          showDataList.data = res.data.data.dataList
          showDataList.totalCount = res.data.data.totalCount
        }
      })
    }
    getNowPost()

    //切換文章類型 全部/草稿
    function changePostType(value){
      searchInput.data.status = value
      getNowPost()
    }

    //前往編輯頁
    function editPage(type, item){
      if (type ==='edit'){
        router.push({ name: 'AdminPostEdit', query: { type:'edit',id:item.id} })
      }
      if (type ==='new'){
        router.push({ name: 'AdminPostEdit', query: { type:'new',id:''} })
      }
    }

    //前往文章前台頁面
    function monthDataLink(item){
      let link = '/post'
      for (let i = 0; i < item.class.length; i++){
        link = link + '/' + item.class[i]
      }
      link = link  + '/' + item.postName
      return link
    }

    return{
      showDataList,
      searchInput,
      classSelect,
      getNowPost,
      changePostType,
      editPage,
      sizeList,
      monthDataLink
    }
  }
}
</script>

<style scoped>

</style>

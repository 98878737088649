import axios from "axios";
import {api} from "@/api/api";

export function ClassSelectList(list, key, lavel){
    const adminToken = sessionStorage.getItem("token") //取得token
    //取得分類列表
    axios.get(api.adminClassSelect+'?filterClassTw='+key, {headers: {Authorization: 'Bearer '+adminToken}}).then(res => {
        if (res.data.msg === "success"){
            let getData  = res.data.data
            list.push({id:0, classTw:'所有分類',class:'', classesIds:''})
            for (let i=0; i<getData.length; i++){
                if (getData[i].children ===[]){
                    getData[i].classesIds = getData[i].id.toString()
                    list.push(getData[i])
                }else {
                    let haveSon = getData[i]
                    haveSon.classesIds=haveSon.id.toString()
                    list.push(haveSon)
                    for (let c=0; c < haveSon.children.length; c++){
                        haveSon.children[c].classTw = '　'+haveSon.children[c].classTw
                        haveSon.children[c].classesIds = haveSon.id+','+haveSon.children[c].id

                        list.push(haveSon.children[c])

                        if (lavel === 3){
                            let haveSon2 = haveSon.children[c].children
                            if (haveSon2 !==[]){
                                for (let d=0; d < haveSon2.length; d++){
                                    haveSon2[d].classTw = '　　 '+haveSon2[d].classTw
                                    haveSon2[d].classesIds = haveSon.id+','+haveSon.children[c].id+','+haveSon2[d].id
                                    list.push(haveSon2[d])
                                }
                            }
                        }
                    }
                }
            }
        }
    })
    return {
        list
    }
}
